.logo-container {
    text-align: center; /* Center the logo */
    padding: 20px; /* Optional: Add some padding around the logo */
}

.logo {
    width: 2.5rem; /* Set the desired width */
    height: auto; /* Maintain the aspect ratio */
    display: block; /* Center the logo if its container has text-align: center */
    margin: 0 0px; /* Center the logo horizontally */
    mix-blend-mode: multiply;
    filter: contrast(1);
    background: #fbd290;
}

.logo-text{
    text-transform: none;
    color: #243151;
}
